import { DenticareEnvi } from '@environments/interfaces/denticare.interface';

export const environment: DenticareEnvi = {
  production: true,
  api: 'https://api.denticarepaymentplans.com.au/pa/v2',
  authBaseURL: 'https://api.denticarepaymentplans.com.au/paauth/v2',
  basiq: {
    apiBaseUrl: 'https://api.denticarepaymentplans.com.au/basiq/v1/api',
    consentUrl: 'https://consent.basiq.io/home',
  },
  apimKey: '9d36a4620f4e44698494b034b3968f0b',
  client: 'DentiCare',
  company_brand: 'DENTICARE',
  brand_provider_relations_email: 'providerrelations@denticarepayplans.com.au',
  stepperPointer: false,
  pbKey: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDK41h+8I8N2KKy0kswAW3Gzkhg
  aR7qdEbe/cUDx/4rbSDOtih1tgqC+YxCitINZIvjwLbIXdVcxdOVeeSxdOah1KKG
  5YNDn8qiSmENiKRMOR0S+vHuvpsBDYJmPbGlUrS5ZSiB78cT2YLhajoPnOCH7yw6
  Q4fB784o5BItOv7UsQIDAQAB
  -----END PUBLIC KEY-----`,
  payload_encrypt: 'true',
  dateFormat: 'dd/MM/yyyy',
  dateFormatMaterial: 'DD/MM/YYYY',
  itemPerPage: 10,
  rules_json_url: 'https://api.denticarepaymentplans.com.au/rules',
  rules_json: {
    global: {
      offline_mode: true,
      maintenance_mode: false
    },
    product: {
      mobile: {
        denticare: {
          offline_mode: false,
          android_min_build: '18',
          android_min_version: '1.1.2',
          ios_min_version: '1.2',
          ios_min_build: '53'
        }
      },
      portal: {
        denticare: {
          offline_mode: true,
          maintenance_mode: false,
          maintenance_message:
            'Your portal is currently undergoing a system upgrade to ensure you have access to the best possible experience. The upgrade is expected to continue for the next 24 hours at most. We expect your access to be reinstated on ????. <br>—Thank you for your understanding',
          clear_cache_datetime: 1630575248188
        },
        transacty: {
          offline_mode: false,
          maintenance_mode: false,
          maintenance_message:
            'Your portal is currently undergoing a system upgrade to ensure you have access to the best possible experience. The upgrade is expected to continue for the next 24 hours at most. We expect your access to be reinstated on ????. <br>—Thank you for your understanding',
          clear_cache_datetime: 1630575248188
        }
      }
    }
  },
  harmonyBaseURL: 'https://hosted.mastersoftgroup.com/harmony/rest/AU/address',
  defaultCountry: 'AU',
  OTPType: 1,
  OTPTranType: 'customrecord_ddr_in_progress',
  modules: {
    general: {
      shared: {
        input: {
          autocomplete: {
            enabled: true
          }
        }
      },
      treatmentType: {
        show: true
      }
    },
    payments: {
      smsJourney: {
        skipBsbValidation: true,
        successScreen: {
          rpAppDetails: {
            showRpAppLink: true,
            playStoreLink: 'https://play.google.com/store/apps/details?id=com.payvantageglobal.dcpatient',
            appStoreLink: 'https://apps.apple.com/au/app/denticare/id1534707515'
          }
        }
      },
      createPlanJourney: {}
    },
    homepage: {
      showVideo: true
    },
    marketingCenter: {
      showWebsiteBadgeDownloadBtn: false
    }
  },
  files: {
    maxSize: 10000000 // 10MB
  },
  defaultCurrency: 'en-AU'
};
